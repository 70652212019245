import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import {
    LocationOptions
} from 'components/advertisementsFilter/components/common/common/location/partials/autocomplete/partials/options/Options';

interface IAutocomplete {
    title?: string;
    isWithParents?: boolean;
    width?: string | number;
    isLastSearched?: boolean;
    options: IAutocompleteOption[];
    onChangeLocation: (location: ILocation) => void;
}

export const Autocomplete: FC<IAutocomplete> = ({
    title,
    width,
    options,
    isWithParents,
    isLastSearched,
    onChangeLocation
}) => {

    return <Box
        width={width}
        pt={{ xs: 2, md: 3 }}
        pb={{ xs: 0, md: 3 }}
    >
        {title &&
            <Box
                paddingX={{ xs: 0, md: 4 }}
                paddingBottom={1}
            >
                <Text
                    semibold
                    variant='label1'
                    color={(palette) => palette.labelSecondary}
                >
                    {title}
                </Text>
            </Box>
        }

        <LocationOptions
            options={options}
            isWithParents={isWithParents}
            isLastSearched={isLastSearched}
            onChangeLocation={onChangeLocation}
        />
    </Box>;
};