import { FC } from 'react';
import { Box } from '@mui/material';
import { Input } from 'modules/theme/components/fields/input/Input';
import { EInputs } from 'components/advertisementsFilter/enums/inputs/EInputs';
import { ClearInput } from 'components/advertisementsFilter/components/common/common/clearInput/ClearInput';

interface ICategoryInput {
    label: string;
    value: string;
    isInFocus: boolean;
    placeholder: string;
    onCategoriesReset: () => void;
    onClick: (target: HTMLElement) => void;
    onSetInputInFocus: (value: EInputs) => void;
}

export const CategoryInput: FC<ICategoryInput> = ({
    label,
    value,
    onClick,
    isInFocus,
    placeholder,
    onSetInputInFocus,
    onCategoriesReset,
}) => {

    return <Box
        id='filter.categoryInput'
        onClick={(event) => onClick(event.currentTarget)}
        sx={{
            paddingRight: 1,
        }}
    >
        <Input
            labelShrink
            value={value}
            label={label}
            autoFocus={false}
            autoComplete='off'
            placeholder={placeholder}
            onChange={() => false}
            onFocus={() => onSetInputInFocus(EInputs.CATEGORY)}
        />

        {value &&
            <ClearInput
                onClick={onCategoriesReset}
                margin={isInFocus ? 116 : 58}
            />
        }
    </Box>;
};