import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IDesktopFilterWrapper {
    height?: number;
    maxWidth?: number;
    isFocused: boolean;
    children: ReactNode;
}

export const DesktopFilterWrapper: FC<IDesktopFilterWrapper> = ({
    height,
    maxWidth,
    children,
    isFocused,
}) => {

    return <Box
        gap={1}
        display='flex'
        height={height}
        alignItems='center'
        borderRadius='99px'
        maxWidth={maxWidth}
        boxShadow={'0px 4px 16px 0px rgba(0, 0, 0, 0.08)'}
        border={(theme) => `1px solid ${theme.palette.dividerTransparent}`}
        bgcolor={(theme) => isFocused
            ? theme.palette.backgroundTertiary.main
            : theme.palette.backgroundPrimary.main
        }
    >
        {children}
    </Box>;
};