import { FC } from 'react';
import { Box } from '@mui/material';
import {
    TransactionInput
} from 'components/advertisementsFilter/components/common/common/transaction/partials/input/TransactionInput';
import {
    ITransaction as ITransactionOption
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import {
    CompleteBox
} from 'components/advertisementsFilter/components/common/common/transaction/partials/completeBox/CompleteBox';
import { Popover } from 'components/advertisementsFilter/components/common/common/popover/Popover';
import { EInputs } from 'components/advertisementsFilter/enums/inputs/EInputs';
import { useTransactionOptions } from 'components/advertisementsFilter/hooks/transaction/options/useTransactionOptions';

interface ITransaction {
    isInFocus: boolean;
    isHovered: boolean;
    isPopoverOpen: boolean;
    onClosePopover: () => void;
    popoverAnchorEl: HTMLElement;
    transactions: ITransactionOption[];
    selectedTransaction?: ITransactionOption;
    onChangeHoverState: (flag: EInputs) => void;
    onSetInputInFocus: (value: EInputs) => void;
    onChangeTransaction: (value: number) => void;
    onOpenPopover: (target: HTMLElement) => void;
}

export const Transaction: FC<ITransaction> = ({
    isInFocus,
    isHovered,
    transactions,
    isPopoverOpen,
    onOpenPopover,
    onClosePopover,
    popoverAnchorEl,
    onSetInputInFocus,
    onChangeHoverState,
    selectedTransaction,
    onChangeTransaction,
}) => {
    const {
        selectedOptionTitle
    } = useTransactionOptions(transactions, selectedTransaction);

    return <>
        <TransactionInput
            label='Ponuka'
            isHovered={isHovered}
            isInFocus={isInFocus}
            clearButtonMargin={10}
            onClick={onOpenPopover}
            placeholder='Celá ponuka'
            value={selectedOptionTitle}
            onSetInputInFocus={onSetInputInFocus}
            onChangeHoverState={onChangeHoverState}
            onChangeTransaction={onChangeTransaction}
        />

        <Popover
            isOpen={isPopoverOpen}
            onClose={() => {
                onClosePopover();
                onSetInputInFocus(null);
            }}
            anchorEl={popoverAnchorEl}
            offsetOptions={{x: 52, y: 20}}
        >
            <Box
                paddingY={2}
            >
                <CompleteBox
                    options={transactions}
                    transaction={selectedTransaction?.id}
                    onTransactionChange={(value) => {
                        const newValue = selectedTransaction && selectedTransaction.id === value
                            ? null : value;

                        onChangeTransaction(newValue);
                        onClosePopover();
                    }}
                />
            </Box>
        </Popover>
    </>;
};
