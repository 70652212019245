import { useEffect, useCallback } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { useRecoilState } from 'recoil';
import {
    IAdvertisementListingFilter
} from 'modules/api/clients/advertisement/filter/interfaces/IAdvertisementListingFilter';
import {
    IAdvertisementListingFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { useSearchFilterQueryParameters } from 'components/advertisementsFilter/hooks/query/parameters/useSearchFilterQueryParameters';
import { QueryParamValueType } from 'components/advertisementsFilter/types/queryFilter/queryParam/QueryParamValueType';
import {
    usePreviouslySearchedLocations
} from 'components/advertisementsFilter/hooks/location/previouslySearched/usePreviouslySearchedLocations';
import { mapPathnameToOld } from 'modules/advertisementsFilter/query/mappers/mapPathnameToOld';
import {
    ADVERTISEMENT_NEW_HP_TO_OLD_RESULTS,
    ADVERTISEMENT_SEARCH,
    ADVERTISEMENT_SEARCH_OLD
} from 'modules/route/routes';
import { createUrlQuery } from 'modules/advertisementsFilter/query/creators/createUrlQuery';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { getSearchEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import {IGtmEvent} from 'modules/analytics/IAnalytics';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { renameDistrictLocation } from 'components/advertisementsFilter/helpers/location/renameLocation';
import {
    advertisementsListFilter,
    advertisementsListFilterPagination
} from 'modules/state/page/listing/search/state';
import useLoading from 'modules/state/app/hook/useLoading';

interface IReturn {
    onResetQueryFilter: () => void;
    setPaginationOrder: (order: EOrder) => void;
    onRedirectByFilter: (order?: EOrder) => void;
    setQueryParameter: (parameterName: string, parameterValue: QueryParamValueType) => void;
}

export const useSearchFilterQuery = (
    initialFilter?: IAdvertisementListingFilter,
    initialSearchFilterData?: IInitialSearchFilterData,
): IReturn => {
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const { start: startLoading } = useLoading();

    const router = useRouter();
    const pathname = usePathname();

    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);
    const [currentFilter, setCurrentFilter] = useRecoilState<IAdvertisementListingFilterData>(advertisementsListFilter);
    const [currentPagination, setCurrentPagination] = useRecoilState<IAdvertisementListingFilterPagination>(advertisementsListFilterPagination);

    useEffect(() => {
        setCurrentFilter(initialFilter.data);
        setCurrentPagination(initialFilter.pagination);
    }, [initialFilter, setCurrentFilter, setCurrentPagination]);

    const { addCookieOption } = usePreviouslySearchedLocations();

    const {
        categoryId,
        getDataToSef,
        transactionId,
    } = useSearchFilterQueryParameters(
        currentFilter,
        initialSearchFilterData,
    );

    const setQueryParameter = useCallback((parameterName: string, parameterValue: QueryParamValueType) => {
        setCurrentFilter(oldFilter => ({ ...oldFilter, [parameterName]: parameterValue }));
    }, [setCurrentFilter]);

    const setPaginationOrder = useCallback((order: EOrder) => {
        if (currentPagination?.order === order) return;

        setCurrentPagination(oldPagination => ({ ...oldPagination, order }));
    }, [currentPagination?.order, setCurrentPagination]);

    const onRedirectByFilter = useCallback(async (order?: EOrder) => {
        const dataToSef = await getDataToSef(
            currentFilter?.locationId,
            categoryId,
            transactionId,
        );

        if (dataToSef.location) {
            const isCity = dataToSef.location.type === ELocationType.CITY;

            addCookieOption({
                id: dataToSef.location.id,
                type: dataToSef.location.type,
                sefName: dataToSef.location.sefName,
                subName: isCity && dataToSef.location.parent?.name,
                name: renameDistrictLocation(dataToSef.location).name,
            });
        }

        // @TODO - REDIRECT TO OLD NSK - remove when new listing is out
        const isHomePage = pathname === '/';

        const paginationOrder = order ? order : currentPagination?.order;
        const { pathname: newPathname, queryParams } = createUrlQuery(
            currentFilter,
            initialSearchFilterData.categories,
            dataToSef,
            paginationOrder,
            isHomePage,
        );

        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getSearchEvent(
                currentFilter,
                dataToSef?.location?.sefName,
                transactionId,
                isHomePage,
                isAdvertiserLoggedIn
            ),
        ];

        gtmSendEvent(...events);

        const pathnameMapped = isHomePage
            ? mapPathnameToOld(newPathname)
            : newPathname;

        let pathnameToRedirect = isHomePage
            ? ADVERTISEMENT_NEW_HP_TO_OLD_RESULTS(pathnameMapped)
            : ADVERTISEMENT_SEARCH(newPathname);


        // @TODO remove when new listing is out
        // NSK-31489 v pripade ze url obsahuje v sebe akykolvek SEF substring tak odstranujeme '/vyhladavanie' z url
        if (pathnameToRedirect !== `${ADVERTISEMENT_SEARCH_OLD}/`) {
            pathnameToRedirect = pathnameToRedirect.replace(
                ADVERTISEMENT_SEARCH_OLD,
                ''
            );
        }

        let queryParamsOldListing = '';
        const queryParamCategories = queryParams.categories as string[];

        const encodedParams = encodeURI('[categories][ids]');
        if (queryParamCategories && queryParamCategories.length) {
            queryParamsOldListing += `p${encodedParams}=${queryParamCategories.join('.')}`;
        }

        const params = new URLSearchParams();
        Object.entries(queryParams).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(value => params.append(key, value.toString()));
            } else {
                params.append(key, value.toString());
            }
        });

        const windowPath = window.location.pathname + window.location.search;
        const queryParamsAll = !isHomePage ? params.toString() : queryParamsOldListing;
        const queryParamsForPath = Object.keys(queryParamsAll).length ? `?${queryParamsAll}`: '';
        const pathString = `${pathnameToRedirect}${queryParamsForPath}`;

        if (windowPath !== pathString) {
            startLoading();
        }

        router.push(pathString);
    }, [
        router,
        pathname,
        categoryId,
        startLoading,
        getDataToSef,
        currentFilter,
        transactionId,
        addCookieOption,
        currentPagination,
        gtmSendEvent,
        isAdvertiserLoggedIn,
        initialSearchFilterData,
    ]);

    const onResetQueryFilter = useCallback(() => {
        setCurrentFilter(oldFilter => ({
            ...oldFilter,
            category: undefined,
            locationId: undefined,
            transaction: undefined
        }));
    }, [setCurrentFilter]);

    return {
        setQueryParameter,
        onRedirectByFilter,
        onResetQueryFilter,
        setPaginationOrder,
    };
};