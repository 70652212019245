import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    IFilterUrlDataLocation
} from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/partials/location/IFilterUrlDataLocation';
import { IAutocompleteOption } from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { fetchLocationSuggestions } from 'components/advertisementsFilter/api/locations/fetchLocationSuggestions';
import { SetQueryParamCb } from 'components/advertisementsFilter/types/queryFilter/queryParam/SetQueryParamCb';
import { renameDistrictLocation } from 'components/advertisementsFilter/helpers/location/renameLocation';
import { Limit } from 'components/advertisementsFilter/constants/config/location/suggestion/Limit';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';

interface IReturn {
    locationQuery: string;
    options: IAutocompleteOption[];
    locationOptionsInitial: ILocation[];
    selectedLocation?: IAutocompleteOption;
    onChangeQuery: (value: string) => void;
    onChangeLocation: (locationToChange: ILocation | null) => void;
}

export const useLocationsAutocomplete = (
    initialLocations: ILocation[],
    setQueryParameter: SetQueryParamCb,
    locationFromSef?: IFilterUrlDataLocation,
): IReturn => {
    const [locationQuery, setLocationQuery] = useState('');
    const [options, setOptions] = useState<IAutocompleteOption[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<ILocation>();

    const appendParentNameToSubName = useCallback(
        (location: ILocation): IAutocompleteOption => {
            const {parent, type} = location;
            const typesWithSubName = [ELocationType.CITY, ELocationType.CITY_PART];

            const hasSubName = typesWithSubName.includes(type);
            return !!hasSubName
                ? {...location, subName: `${parent.name}`}
                : location;
        }, []);

    useEffect(() => {
        if (!locationFromSef) return;

        setLocationQuery(renameDistrictLocation(locationFromSef).name);
        setSelectedLocation({
            id: locationFromSef.id,
            type: locationFromSef.type,
            sefName: locationFromSef.sefName,
            name: renameDistrictLocation(locationFromSef).name,
        });
    }, [locationFromSef]);

    const onChangeLocation = useCallback((locationToChange: IAutocompleteOption): void => {
        setSelectedLocation(locationToChange);
        setQueryParameter('locationId', locationToChange && locationToChange.id);

        if (locationToChange) setLocationQuery(locationToChange.name);
    }, [setQueryParameter]);

    const onChangeQuery = useCallback((value: string) => {
        if (!value) {
            setOptions([]);
            setSelectedLocation(undefined);
            setQueryParameter('locationId', null);
        }

        setLocationQuery(value);
    }, [setQueryParameter]);

    const fetchLocations = useCallback(async (query: string) => {
        if (!locationQuery || locationQuery.length <= 1) return;

        try {
            const locations = await fetchLocationSuggestions(query, Limit);

            const locationsDistrictsRenamed = locations.map((location) =>
                renameDistrictLocation(location)
            );

            const locationsWithParentNames = locationsDistrictsRenamed.map((location) =>
                appendParentNameToSubName(location)
            );

            setOptions(locationsWithParentNames);
        } catch (error) {
            console.error(error);
        }
    }, [locationQuery, appendParentNameToSubName]);

    const debouncedFetchLocations = useDebouncedCallback(fetchLocations, 250);

    useEffect(() => {
        (async () => {
            await debouncedFetchLocations(locationQuery);
        })();
    }, [locationQuery, debouncedFetchLocations, onChangeQuery]);

    return {
        options,
        locationQuery,
        onChangeQuery,
        selectedLocation,
        onChangeLocation,
        locationOptionsInitial: initialLocations,
    };
};
