import {
    IFilterUrlDataLocation
} from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/partials/location/IFilterUrlDataLocation';
import { kosiceAndBratislavaIds } from 'modules/advertisementsFilter/query/constants/location/kosiceAndBratislavaIds';
import {
    BratislavaAndKosiceLocationIdsToEBratislavaAndKosiceSefs
} from 'modules/advertisementsFilter/query/constants/location/BratislavaAndKosiceLocationIdsToEBratislavaAndKosiceIds';
import { CzechiaId } from 'modules/advertisementsFilter/query/constants/location/CzechiaId';
import { ELocationType } from 'modules/api/clients/location/enums/type/ELocationType';
import { createSeoString } from 'modules/seoString/createSeoString';

export const mapLocationSefNameToOldNskSefName = (location: IFilterUrlDataLocation): string => {
    let sefName = location.sefName;

    // hack pre CZ, pretoze na plt je sefName cesko a nie ceska-republika
    if (location.id === CzechiaId) {
        sefName = createSeoString(location.name);
    }

    // hack pre mestske casti, pretoze stare neh maju sef name MESTO-MESTKA_CAST napr zilina-vlcince, plt ma len vlcince
    if (location.type === ELocationType.CITY_PART) {
        const {
            sefName: locationSeName,
            parent: { sefName: parentSefName },
        } = location;

        sefName = [parentSefName, locationSeName].join('-');
    }

    // hack pre BA & KE
    if (kosiceAndBratislavaIds.includes(location.id)) {
        sefName = BratislavaAndKosiceLocationIdsToEBratislavaAndKosiceSefs[
            location.id
        ];
    }

    return sefName;
};