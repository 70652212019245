import { atom } from 'recoil';
import {
    IAdvertisementListingFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';

export const advertisementsListFilter = atom<IAdvertisementListingFilterData>({
    key: 'advertisements-list-filter',
    default: null,
});

export const advertisementsListFilterPagination = atom<IAdvertisementListingFilterPagination>({
    key: 'advertisements-list-filter-pagination',
    default: null,
});
