import {useCallback, useRef, useState} from 'react';

interface IReturn {
    swiperRef: any, // eslint-disable-line
    isEnd: boolean,
    isBeginning: boolean,
    setIsEnd: (isLastItem: boolean) => void,
    setIsBeginning: (isFirstItem: boolean) => void,
}

export const useCarousel = (): IReturn => {
    const swiperRef = useRef(null);
    const [isFirstItem, setIsFirstItem] = useState<boolean>(true);
    const [isLastItem, setIsLastItem] = useState<boolean>(false);

    const setIsEnd = useCallback((isLastItem: boolean) => {
        setIsLastItem(isLastItem);
    }, []);

    const setIsBeginning = useCallback((isFirstItem: boolean) => {
        setIsFirstItem(isFirstItem);
    }, []);

    return {
        setIsEnd,
        swiperRef,
        setIsBeginning,
        isEnd: isLastItem,
        isBeginning: isFirstItem,
    };
};

