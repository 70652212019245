import React, {FC, FunctionComponent, SVGProps} from 'react';
import {Box} from '@mui/material';
import {
    RadioCardContent
} from 'components/advertisementsFilter/components/common/common/radioCard/common/content/RadioCardContent';

interface ILocationRadioCard {
    label: string;
    isMain?: boolean;
    noTitle?: boolean;
    isChecked: boolean;
    onClick: () => void;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const LocationRadioCard: FC<ILocationRadioCard> = ({
    icon,
    label,
    isMain,
    noTitle,
    onClick,
    isChecked,
}) => {

    return (
        <Box
            px={1.5}
            py={isMain && 2}
            onClick={onClick}
            textAlign='center'
            alignItems='center'
            borderRadius='12px'
            gap={isMain ? 0.5 : 1}
            data-test-id='locationRadioCardCustom'
            flexDirection={isMain ? 'column' : 'row'}
            justifyContent={isMain ? 'center' : 'flex-start'}
            sx={(theme) => ({
                cursor: 'pointer',
                outline: isChecked
                    ? `2px solid ${theme.palette.labelPrimary.main}`
                    : `1px solid ${theme.palette.dividerTransparent}`,
                backgroundColor: isChecked
                    ? theme.palette.backgroundSecondary.main
                    : theme.palette.backgroundPrimary.main,
                border: isChecked
                    ? `1px solid ${theme.palette.labelPrimary.main}`
                    : '1px solid transparent',
                '&:hover': {
                    border: `1px solid ${theme.palette.labelPrimary.main}`,
                    outline: `1px solid ${theme.palette.labelPrimary.main}`,
                    backgroundColor: {
                        md: theme.palette.backgroundSecondary.main
                    }
                },
                '& > svg': {
                    marginBottom: '-5px'
                }
            })}
        >
            <RadioCardContent
                noWrap
                icon={icon}
                label={label}
                iconWidth={24}
                noTitle={noTitle}
            />
        </Box>
    );
};