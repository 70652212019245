import { FC } from 'react';
import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { IGridCategory } from 'components/advertisementsFilter/interfaces/category/grid/IGridCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { CategoryRadioCard } from 'components/advertisementsFilter/components/common/common/radioCard/category/CategoryRadioCard';
import {
    boxWidthConfig,
    boxHeightConfig,
} from 'components/advertisementsFilter/constants/config/completeBoxes/boxWidthAndHeight/boxWidthAndHeightConfig';

interface ICategoryGridItem {
    item: IGridCategory;
    onChange: (value: number) => void;
}

export const CategoryGridItem: FC<ICategoryGridItem> = ({ item, onChange }) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const boxWidth = (parentEName: EMainCategory | ESubCategory): number => {
        if (parentEName === EMainCategory.SECONDARY_LANDS) return boxWidthConfig.category.parentLands;
        if (parentEName === EMainCategory.SECONDARY_SPACES_AND_OBJECTS) return boxWidthConfig.category.parentSpacesAndObjects;
        return boxWidthConfig.category.default;
    };

    const boxHeight = (parentEName: EMainCategory | ESubCategory): number | string => {
        if (!isMobile) return '100%';

        if (parentEName === EMainCategory.SECONDARY_LANDS ||
            parentEName === EMainCategory.SECONDARY_SPACES_AND_OBJECTS
        ) return boxHeightConfig.category.sub.mobile;
        return boxHeightConfig.category.sub.desktop;
    };

    return (
        <Grid item xs={12}>
            <Box mb={2}>
                <Text variant="label1" semibold>
                    {item.name}:
                </Text>
            </Box>

            <Grid container spacing={1}>
                {item.children.map((categoryOption) => (
                    <Grid item xs={isMobile && 6} key={categoryOption.id}>
                        <CategoryRadioCard
                            type='subcategory'
                            icon={categoryOption.icon}
                            label={categoryOption.name}
                            isChecked={categoryOption.isSelected}
                            onClick={() => onChange(categoryOption.id)}
                            height={boxHeight(categoryOption.parentEName)}
                            width={!isMobile && boxWidth(categoryOption.parentEName)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};
