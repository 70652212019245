import {
    IAdvertisementListingFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import { ICategories } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import { IFilterUrlData } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/IFilterUrlData';
import { IUrlQuery } from 'modules/advertisementsFilter/query/interfaces/IUrlQuery';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';
import {
    mapQueryParamCategoryToOld,
    categoriesToExcludeFromSefOnly
} from 'modules/advertisementsFilter/query/mappers/mapQueryParamCategoryToOld';
import {
    mapLocationSefNameToOldNskSefName
} from 'modules/advertisementsFilter/query/mappers/mapLocationSefNameToOldNskSefName';

export const createUrlQuery = (
    currentFilter: IAdvertisementListingFilterData,
    initialCategories: ICategories,
    dataToSef: IFilterUrlData,
    paginationOrder: EOrder,
    withRedirectToOldListing: boolean,
): IUrlQuery => {
    const pathname: string[] = [];
    let queryParams: {[key: string]: string | string[]} = {};

    if (!currentFilter?.categories) {
        queryParams = {};
    }

    if (withRedirectToOldListing) {
        const allCategories = [];
        if (dataToSef.category) {
            allCategories.push(dataToSef.category.name);
        } else {
            allCategories.push(...currentFilter?.categories);
        }

        if (allCategories.length === 1) {
            if (!categoriesToExcludeFromSefOnly.includes(allCategories[0])) {
                pathname.push(dataToSef.category.sefName);
            } else {
                queryParams.categories = mapQueryParamCategoryToOld(
                    allCategories,
                );
            }
        } else {
            queryParams.categories = mapQueryParamCategoryToOld(
                allCategories,
            );
        }
    } else {
        if (currentFilter?.categories && currentFilter?.categories.length > 1) {
            const allCategories = [
                ...initialCategories.main,
                ...initialCategories.sub
            ];

            const categoriesFiltered = allCategories.filter(
                (category) => currentFilter?.categories.includes(category.name)
            );
            queryParams.categories = categoriesFiltered.map((category) => category.id.toString());
        } else {
            if (dataToSef.category) {
                pathname.push(dataToSef.category.sefName);
            }
        }
    }

    if (dataToSef.location) {
        if (withRedirectToOldListing) {
            pathname.push(
                mapLocationSefNameToOldNskSefName(dataToSef.location),
            );
        } else {
            pathname.push(dataToSef.location.sefName);
        }
    }

    if (dataToSef.transaction) {
        pathname.push(dataToSef.transaction.sefName);
    }

    if (currentFilter?.priceFrom) {
        queryParams.priceFrom = currentFilter?.priceFrom.toString();
    } else {
        delete queryParams.priceFrom;
    }

    if (currentFilter?.priceTo) {
        queryParams.priceTo = currentFilter?.priceTo.toString();
    } else {
        delete queryParams.priceTo;
    }

    if (currentFilter?.areaFrom) {
        queryParams.areaFrom = currentFilter?.areaFrom.toString();
    } else {
        delete queryParams.areaFrom;
    }

    if (currentFilter?.areaTo) {
        queryParams.areaTo = currentFilter?.areaTo.toString();
    } else {
        delete queryParams.areaTo;
    }

    if (currentFilter?.onlyWithVideo) {
        queryParams.onlyWithVideo = '1';
    } else {
        delete queryParams.onlyWithVideo;
    }

    if (currentFilter?.onlyNewbuilding) {
        queryParams.onlyNewbuilding = '1';
    } else {
        delete queryParams.onlyNewbuilding;
    }

    if (currentFilter?.fulltext) {
        queryParams.fulltext = currentFilter?.fulltext;
    } else {
        delete queryParams.fulltext;
    }

    if (EOrder[paginationOrder] && paginationOrder !== EOrder.DEFAULT) {
        queryParams.order = paginationOrder;
    }

    return {
        pathname: pathname.join('/'),
        queryParams,
    };
};