import { useCallback } from 'react';

interface IReturn {
    onReset: () => void;
    onExtendedFilterReset: () => void;
}

export const useSearchFiltersReset = (
    onSearchFilterReset: () => void,
    onResetQueryFilter: () => void,
    onSearchFilterExtendedReset?: () => void,
    onHistogramPricesReset?: () => void,
): IReturn => {

    const onReset = useCallback((): void => {
        onResetQueryFilter();
        onSearchFilterReset();
    }, [
        onResetQueryFilter,
        onSearchFilterReset,
    ]);

    const onExtendedFilterReset = useCallback((): void => {
        if (onSearchFilterExtendedReset !== undefined) {
            onSearchFilterExtendedReset();
        }

        if (onHistogramPricesReset !== undefined) {
            onHistogramPricesReset();
        }
    }, [
        onHistogramPricesReset,
        onSearchFilterExtendedReset,
    ]);

    return {
        onReset,
        onExtendedFilterReset,
    };
};