import { useCallback, useState } from 'react';

interface IReturn {
    isFilterOpened: boolean;
    toggleFilter: (flag: boolean) => void;
}

export const useMobileFilter = (): IReturn => {
    const [isFilterOpened, setIsFilterOpened] = useState(false);

    const toggleFilter = useCallback((flag: boolean) => {
        setIsFilterOpened(flag);
    }, []);

    return {
        toggleFilter,
        isFilterOpened
    };
};