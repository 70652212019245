import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IContentItem {
    text?: string;
    noWrap?: boolean;
    semibold?: boolean;
    isColorSecondary?: boolean;
    variant: 'label1' | 'label2';
}

export const ContentItem: FC<IContentItem> = ({ text, noWrap, semibold, variant, isColorSecondary }) => {
    return <Text
        noWrap={noWrap}
        variant={variant}
        semibold={semibold}
        color={(palette) => isColorSecondary && palette.labelSecondary}
    >
        {text}
    </Text>;
};