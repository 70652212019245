export const boxWidthConfig = {
    category: {
        default: 124,
        parentLands: 213,
        parentSpacesAndObjects: 158,
    },
};

export const boxHeightConfig = {
    category: {
        main: {
            mobile: 94,
            desktop: 112,
        },
        sub: {
            desktop: 48,
            mobile: 56,
        }
    },
};