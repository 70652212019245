import { useTypedText } from 'components/advertisementsFilter/hooks/mobileFilter/animatedPlaceholder/useTypedText';

export const useSuggestPlaceholder = (): string => {
    const text = 'Začnite hľadať tu ...';

    const placeholder = useTypedText(text, {
        betweenCharactersDelay: 100,
        removeTextDelay: 5000,
    });

    return placeholder;
};