import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IMobileFilterWrapper {
    height?: number;
    children: ReactNode;
}

export const MobileFilterWrapper: FC<IMobileFilterWrapper> = ({ height, children }) => {

    return <Box
        gap={2}
        top={0}
        width='100%'
        display='flex'
        height={height}
        alignItems='center'
        justifyContent='space-between'
    >
        {children}
    </Box>;
};
