import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { useSuggestPlaceholder } from 'components/advertisementsFilter/hooks/mobileFilter/animatedPlaceholder/useSuggestPlaceholder';

export const AnimatedPlaceholder: FC = () => {
    const placeholder = useSuggestPlaceholder();

    return <Text
        semibold
        variant='body2'
        color={(palette) => palette.labelSecondary}
    >
        {placeholder}
    </Text>;
};