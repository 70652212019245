import { FC } from 'react';
import { Box } from '@mui/material';
import {
    InputContent
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterHeader/partials/multiInput/partials/content/Content';
import {
    AnimatedPlaceholder
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterHeader/partials/multiInput/partials/animatedPlaceholder/AnimatedPlaceholder';
import {
    SearchButton
} from 'components/advertisementsFilter/components/common/desktopFilter/partials/searchButton/SearchButton';

interface IMultiInput {
    height?: number;
    location?: string;
    category?: string;
    transaction?: string;
    isWithBackground?: boolean;
    isWithSearchButton?: boolean;
    onMobileFilterOpen: () => void;
}

export const MultiInput: FC<IMultiInput> = ({
    height,
    location,
    category,
    transaction,
    isWithBackground,
    onMobileFilterOpen,
    isWithSearchButton,
}) => {
    const isNothingSelected = !location && !category && !transaction;

    return <Box
        gap={1}
        width='100%'
        paddingY={1.5}
        display='flex'
        paddingX={1.25}
        height={height}
        overflow='hidden'
        alignItems='center'
        borderRadius='99px'
        onClick={onMobileFilterOpen}
        justifyContent={isWithSearchButton && 'space-between'}
        boxShadow={'0px 4px 16px 0px rgba(0, 0, 0, 0.08)'}
        border={(theme) => `1px solid ${theme.palette.dividerTransparent}`}
        bgcolor={(theme) => isWithBackground && theme.palette.backgroundPrimary.main}
    >
        <Box
            gap={1.5}
            display='flex'
            paddingLeft={1.5}
            overflow='hidden'
            alignItems='center'
        >
            {isNothingSelected
                ? <AnimatedPlaceholder/>
                : <InputContent
                    location={location}
                    category={category}
                    transaction={transaction}
                />
            }
        </Box>

        {isWithSearchButton &&
            <SearchButton
                isTitleVisible={false}
            />
        }
    </Box>;
};
