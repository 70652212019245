import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { useMemo } from 'react';

interface IReturn {
    showSuggestions: boolean;
    showBaseLocations: boolean;
    showPreviouslySearchedLocations: boolean;
}

export const useLocationVisibility = (
    locationQuery: string,
    selectedLocation: ILocation,
    autocompleteOptions: IAutocompleteOption[],
    previouslySearchedOptions: ILocation[],
): IReturn => {

    const showSuggestions = useMemo(() => {
        return !!locationQuery.length && !!autocompleteOptions.length;
    }, [locationQuery, autocompleteOptions]);

    const showBaseLocations = useMemo(() => {
        return !selectedLocation && !autocompleteOptions.length;
    }, [selectedLocation, autocompleteOptions]);

    const showPreviouslySearchedLocations = useMemo(() => {
        return !showSuggestions && !selectedLocation && !!previouslySearchedOptions.length;
    }, [showSuggestions, selectedLocation, previouslySearchedOptions]);

    return {
        showSuggestions,
        showBaseLocations,
        showPreviouslySearchedLocations
    };
};