import { useCallback } from 'react';
import {
    ICategory
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';

interface IReturn {
    excludeOrIncludeParentCategory: (
        categoryToSelect: ICategory,
        selectedCategories: ICategory[],
    ) => ICategory[];
}

export const useParentCategories = (): IReturn => {

    const excludeOrIncludeParentCategory = useCallback((
        categoryToSelect: ICategory,
        selectedCategories: ICategory[],
    ): ICategory[] => {
        let categoriesToSelect = selectedCategories;

        if (categoryToSelect.isMain) {
            const areChildrenSubcategoriesSelected = selectedCategories
                .map((selectedCategory) => {
                    const isSelected =
                        selectedCategory.parentId === categoryToSelect.id;

                    if (isSelected) return selectedCategory;
                });

            if (areChildrenSubcategoriesSelected.length) {
                categoriesToSelect = selectedCategories.filter(
                    (selectedCategory) => selectedCategory.parentId !== categoryToSelect.id,
                );
            }
        } else {
            categoriesToSelect = selectedCategories.filter(
                (selectedCategory) => selectedCategory.id !== categoryToSelect.parentId,
            );
        }

        return categoriesToSelect;
    }, []);

    return {
        excludeOrIncludeParentCategory,
    };
};