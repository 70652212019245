import { useCallback, useMemo } from 'react';
import { getQueryFilterDataToSef } from 'components/advertisementsFilter/api/dataToSef/getQueryFilterDataToSef';
import { IFilterUrlData } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/IFilterUrlData';
import {
    IAdvertisementListingFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';

interface IReturn {
    categoryId: number;
    transactionId: number;
    getDataToSef: (locationId: string, categoryId: number, transactionId: number) => Promise<IFilterUrlData>;
}

export const useSearchFilterQueryParameters = (
    currentFilter: IAdvertisementListingFilterData,
    initialSearchFilterData: IInitialSearchFilterData
): IReturn => {

    const filterCategories = useMemo(() => {
        return currentFilter?.categories || [];
    }, [currentFilter?.categories]);

    const transactionId = useMemo(() => {
        let transactionId: number;
        if (currentFilter?.transaction) {
            const transactionSelected = initialSearchFilterData.transactions.find(
                (transaction) => transaction.name === currentFilter.transaction);

            if (transactionSelected) {
                transactionId = transactionSelected.id;
            }
        }

        return transactionId;
    }, [currentFilter?.transaction, initialSearchFilterData]);

    const categoryId = useMemo(() => {
        let categoryId: number;
        if (filterCategories && filterCategories.length === 1) {
            const categorySelected = currentFilter?.categories[0];

            const allCategories = [
                ...initialSearchFilterData.categories.main,
                ...initialSearchFilterData.categories.sub
            ];
            const category = allCategories.find(
                (category) => category.name === categorySelected);
            if (category) {
                categoryId = category.id;
            }
        }

        return categoryId;
    }, [currentFilter, filterCategories, initialSearchFilterData]);

    const getDataToSef = useCallback(async (
        locationId?: string,
        categoryId?: number,
        transactionId?: number,
    ): Promise<IFilterUrlData> => {
        try {
            const dataToSef = await getQueryFilterDataToSef(
                locationId ? locationId : undefined,
                categoryId ? categoryId : undefined,
                transactionId ? transactionId : undefined,
            );

            return dataToSef;
        } catch (error) {
            console.error(error);
        }
    }, []);

    return {
        categoryId,
        getDataToSef,
        transactionId,
    };
};