import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box } from '@mui/material';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    BaseLocations
} from 'components/advertisementsFilter/components/common/common/location/partials/completeBox/partials/baseLocations/BaseLocations';

interface ICompleteBox {
    options: ILocation[];
    selectedLocationId: string;
    onChangeLocation: (location: ILocation) => void;
}

export const CompleteBox: FC<ICompleteBox> = ({
    options,
    onChangeLocation,
    selectedLocationId,
}) => {

    return <Box p={{ xs: 0, md: 4 }}>
        <Box mb={2}>
            <Text
                semibold
                variant='label1'
                color={(palette) => palette.labelSecondary}
            >
                Najhľadanejšie lokality:
            </Text>
        </Box>
        <BaseLocations
            options={options}
            onChangeLocation={onChangeLocation}
            selectedLocationId={selectedLocationId}
        />
    </Box>;
};
