import { FC } from 'react';
import {
    CategoryInput
} from 'components/advertisementsFilter/components/common/common/category/partials/input/CategoryInput';
import {
    CompleteBox
} from 'components/advertisementsFilter/components/common/common/category/partials/completeBox/CompleteBox';
import { Popover } from 'components/advertisementsFilter/components/common/common/popover/Popover';
import {
    ICategories
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import { EInputs } from 'components/advertisementsFilter/enums/inputs/EInputs';

interface ICategory {
    isInFocus: boolean;
    isPopoverOpen: boolean;
    onClosePopover: () => void;
    categoryOptions: ICategories;
    popoverAnchorEl: HTMLElement;
    onCategoriesReset: () => void;
    selectedCategoryIds: number[];
    selectedCategoryNames: string;
    onSelectCategory: (value: number) => void;
    onDeselectCategory: (value: number) => void;
    onSetInputInFocus: (value: EInputs) => void;
    onOpenPopover: (target: HTMLElement) => void;
}

export const Category: FC<ICategory> = ({
    isInFocus,
    onOpenPopover,
    isPopoverOpen,
    onClosePopover,
    categoryOptions,
    popoverAnchorEl,
    onSelectCategory,
    onCategoriesReset,
    onSetInputInFocus,
    onDeselectCategory,
    selectedCategoryIds,
    selectedCategoryNames,
}) => {

    return <>
        <CategoryInput
            label='Nehnuteľnosť'
            isInFocus={isInFocus}
            onClick={onOpenPopover}
            value={selectedCategoryNames}
            placeholder='Všetky kategórie'
            onSetInputInFocus={onSetInputInFocus}
            onCategoriesReset={onCategoriesReset}
        />

        <Popover
            isOpen={isPopoverOpen}
            onClose={() => {
                onClosePopover();
                onSetInputInFocus(null);
            }}
            anchorEl={popoverAnchorEl}
            offsetOptions={{x: -180, y: 20}}
        >
            <CompleteBox
                options={categoryOptions}
                onChangeCategory={(categoryId) => {
                    const isAlreadySelected = selectedCategoryIds.includes(
                        categoryId
                    );

                    return isAlreadySelected
                        ? onDeselectCategory(categoryId)
                        : onSelectCategory(categoryId);
                }}
                selectedCategoryIds={selectedCategoryIds}
            />
        </Popover>
    </>;
};
