import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface ISearchButtonWrapper {
    children: ReactNode;
    isTitleVisible: boolean;
}

export const SearchButtonWrapper: FC<ISearchButtonWrapper> = ({ children, isTitleVisible }) => {
    const padding = isTitleVisible ? 14 : 12;

    return <Box sx={{
        zIndex: 10,
        '& .MuiButton-rounded': {
            minWidth: 0,
            padding: `${padding}px!important`
        }
    }}>
        {children}
    </Box>;
};