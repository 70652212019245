import { useEffect, useState } from 'react';

interface IReturn {
    mounted: boolean;
}

// hook for page hydration on client side, will be removed when new nextjs app router will be applied
export const useHydration = (): IReturn => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return {
        mounted,
    };
};