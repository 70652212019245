import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import {
    InputWrapper
} from 'components/advertisementsFilter/components/common/common/inputWrapper/InputWrapper';
import { EInputs } from 'components/advertisementsFilter/enums/inputs/EInputs';

interface ILastItemWrapper {
    isInFocus: boolean;
    isHovered: boolean;
    isTextBold?: boolean;
    isNothingInFocus: boolean;
    inputComponent: ReactNode;
    buttonComponent: ReactNode;
    onChangeHoverState: (flag: EInputs) => void;
}

export const LastItemWrapper: FC<ILastItemWrapper> = ({
    isInFocus,
    isHovered,
    isTextBold,
    inputComponent,
    buttonComponent,
    isNothingInFocus,
    onChangeHoverState,
}) => {

    return <InputWrapper
        isLast={true}
        isCursorPointed
        inputWidth={264}
        isInFocus={isInFocus}
        isHovered={isHovered}
        isTextBold={isTextBold}
        onMouseHover={(value) => {
            const hoverValue = value ? EInputs.CATEGORY : null;
            onChangeHoverState(hoverValue);
        }}
    >
        <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{
                '& button': {
                    maxWidth: isNothingInFocus ? 40 : 100,
                    transition: isNothingInFocus ? 'max-width 0.3s' : 'none',
                }
            }}
        >
            {inputComponent}

            {buttonComponent}
        </Box>
    </InputWrapper>;
};