import { FC, useMemo } from 'react';
import {
    ICategories as ICategoriesOptions
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import {
    CategoryGridItems
} from 'components/advertisementsFilter/components/common/common/category/partials/completeBox/partials/rollableCategories/partials/gridItems/GridItems';
import { useCategoriesGrid } from 'components/advertisementsFilter/hooks/categories/rollable/useCategoriesGrid';

interface IRollableCategories {
    options: ICategoriesOptions;
    selectedCategoryIds: number[];
    onChangeCategory: (value: number) => void;
}

export const RollableCategories: FC<IRollableCategories> = ({
    options,
    onChangeCategory,
    selectedCategoryIds,
}) => {
    const { categoriesGrid } = useCategoriesGrid(options, selectedCategoryIds);

    const items = useMemo(
        () => categoriesGrid.filter((category) => category.isRollable),
        [categoriesGrid]
    );

    return (
        <CategoryGridItems
            items={items}
            onChangeCategory={onChangeCategory}
        />
    );
};
