import { FC } from 'react';
import { Box } from '@mui/material';
import {
    InputWrapper
} from 'components/advertisementsFilter/components/common/common/inputWrapper/InputWrapper';
import { ClearInput } from 'components/advertisementsFilter/components/common/common/clearInput/ClearInput';
import { EInputs } from 'components/advertisementsFilter/enums/inputs/EInputs';
import { Input } from 'modules/theme/components/fields/input/Input';

interface ITransactionInput {
    label: string;
    value: string;
    isHovered: boolean;
    isInFocus: boolean;
    placeholder: string;
    clearButtonMargin?: number;
    onClick: (target: HTMLElement) => void;
    onChangeHoverState: (flag: EInputs) => void;
    onSetInputInFocus: (value: EInputs) => void;
    onChangeTransaction: (value: number) => void;
}

export const TransactionInput: FC<ITransactionInput> = ({
    label,
    value,
    onClick,
    isInFocus,
    isHovered,
    placeholder,
    clearButtonMargin,
    onSetInputInFocus,
    onChangeHoverState,
    onChangeTransaction,
}) => {

    return <InputWrapper
        inputWidth={196}
        isTextBold={!!value}
        isInFocus={isInFocus}
        isHovered={isHovered}
        isCursorPointed={true}
        onMouseHover={(value) => {
            const hoverValue = value ? EInputs.TRANSACTION : null;

            if (typeof onChangeHoverState !== 'undefined') {
                onChangeHoverState(hoverValue);
            }
        }}
    >
        <Box
            id='filter.transactionInput'
            onClick={(event) => onClick(event.currentTarget)}
        >
            <Input
                labelShrink
                value={value}
                label={label}
                autoComplete='off'
                placeholder={placeholder}
                onChange={() => false}
                onFocus={() => onSetInputInFocus(EInputs.TRANSACTION)}
            />

            {value &&
                <ClearInput
                    onClick={() => onChangeTransaction(null)}
                    margin={clearButtonMargin}
                />
            }
        </Box>
    </InputWrapper>;
};