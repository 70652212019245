import {FunctionComponent, SVGProps} from 'react';

import KosiceIcon from 'modules/theme/components/icons/location/KosiceOutline.svg';
import PresovIcon from 'modules/theme/components/icons/location/PresovOutline.svg';
import BanskaBystricaIcon from 'modules/theme/components/icons/location/BanskaBystricaOutline.svg';
import ZilinaIcon from 'modules/theme/components/icons/location/ZilinaOutline.svg';
import BratislavaIcon from 'modules/theme/components/icons/location/BratislavaOutline.svg';
import TrnavaIcon from 'modules/theme/components/icons/location/TrnavaOutline.svg';
import NitraIcon from 'modules/theme/components/icons/location/NitraOutline.svg';
import TrencinIcon from 'modules/theme/components/icons/location/TrencinOutline.svg';

import { EBaseLocationsSefNames } from 'components/advertisementsFilter/enums/location/EBaseLocationsSefNames';

export const baseLocationIconRecord: Record<EBaseLocationsSefNames, FunctionComponent<SVGProps<SVGSVGElement>>> = {
    [EBaseLocationsSefNames.BRATISLAVA]: BratislavaIcon,
    [EBaseLocationsSefNames.TRNAVA]: TrnavaIcon,
    [EBaseLocationsSefNames.NITRA]: NitraIcon,
    [EBaseLocationsSefNames.BANKSA_BYSTRICA]: BanskaBystricaIcon,
    [EBaseLocationsSefNames.TRENCIN]: TrencinIcon,
    [EBaseLocationsSefNames.PRESOV]: PresovIcon,
    [EBaseLocationsSefNames.ZILINA]: ZilinaIcon,
    [EBaseLocationsSefNames.KOSICE]: KosiceIcon,
};