import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ECategoryName } from 'components/advertisementsFilter/enums/category/ECategoryName';

export const apiCategoryToECategoryNameRecord: Partial<Record<EMainCategory | ESubCategory, ECategoryName>> = {
    [EMainCategory.APARTMENTS]: ECategoryName.APARTMENTS,
    [EMainCategory.SECONDARY_HOUSES]: ECategoryName.SECONDARY_HOUSES,
    [EMainCategory.SECONDARY_LANDS]: ECategoryName.SECONDARY_LANDS,
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]: ECategoryName.SECONDARY_RECREATIONAL_PROPERTIES,
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]: ECategoryName.SECONDARY_SPACES_AND_OBJECTS,

    [ESubCategory.SECONDARY_STUDIO_APARTMENT]: ECategoryName.SECONDARY_STUDIO_APARTMENT,
    [ESubCategory.ONE_ROOM_APARTMENT]: ECategoryName.ONE_ROOM_APARTMENT,
    [ESubCategory.TWO_ROOM_APARTMENT]: ECategoryName.TWO_ROOM_APARTMENT,
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]: ECategoryName.SECONDARY_THREE_ROOM_APARTMENT,
    [ESubCategory.FOUR_ROOM_APARTMENT]: ECategoryName.FOUR_ROOM_APARTMENT,
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]: ECategoryName.FIVE_PLUS_ROOM_APARTMENT,
    [ESubCategory.HOLIDAY_APARTMENT]: ECategoryName.HOLIDAY_APARTMENT,
    [ESubCategory.MAISONETTE]: ECategoryName.MAISONETTE,
    [ESubCategory.LOFT]: ECategoryName.LOFT,
    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]: ECategoryName.SECONDARY_LAND_FOR_FAMILY_HOUSES,
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]: ECategoryName.SECONDARY_RECREATIONAL_LAND,
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]: ECategoryName.SECONDARY_COMMERCIAL_PLOTS,
    [ESubCategory.SECONDARY_GARDEN]: ECategoryName.SECONDARY_GARDEN,
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: ECategoryName.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: ECategoryName.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]: ECategoryName.SECONDARY_GARAGE_AND_PARKING,

    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]: ECategoryName.SECONDARY_OFFICES_AND_ADMINISTRATION,
    [ESubCategory.SECONDARY_THE_SHOP]: ECategoryName.SECONDARY_THE_SHOP,
    [ESubCategory.SECONDARY_A_RESTAURANT]: ECategoryName.SECONDARY_A_RESTAURANT,
    [ESubCategory.SECONDARY_WAREHOUSES]: ECategoryName.SECONDARY_WAREHOUSES,
    [ESubCategory.SECONDARY_PRODUCTION]: ECategoryName.SECONDARY_PRODUCTION,
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]: ECategoryName.SECONDARY_HOTEL_GUESTHOUSE,
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: ECategoryName.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
};
