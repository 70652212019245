import { useEffect, useState } from 'react';

interface IDelays {
    betweenCharactersDelay: number;
    removeTextDelay: number;
}

export const useTypedText = (text: string, delays: IDelays): string => {
    const [ charactersShown, setCharactersShown ] = useState<number>(0);

    useEffect(() => {
        setCharactersShown(0);

        if (!text) {
            return;
        }

        const intervalId = setInterval(() => {
            setCharactersShown(charactersShown => {
                if (charactersShown === text.length) {
                    return charactersShown;
                }

                return charactersShown + 1;
            });
        }, delays.betweenCharactersDelay);

        return () => {
            clearInterval(intervalId);
        };
    }, [text, delays.betweenCharactersDelay]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (charactersShown === text.length) {
                setCharactersShown(0);
            }
        }, delays.removeTextDelay);

        return () => {
            clearInterval(intervalId);
        };
    }, [text, charactersShown, delays.removeTextDelay]);

    return text ? text.substring(0, charactersShown) : '';
};