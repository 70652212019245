import { FC } from 'react';
import { Box } from '@mui/material';
import {
    Option
} from 'components/advertisementsFilter/components/common/common/location/partials/autocomplete/partials/options/partials/option/Option';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

interface ILocationOptions {
    isWithParents?: boolean;
    isLastSearched?: boolean;
    options: IAutocompleteOption[];
    onChangeLocation: (location: ILocation) => void;
}
export const LocationOptions: FC<ILocationOptions> = ({
    options,
    isWithParents,
    isLastSearched,
    onChangeLocation
}) => {

    return <Box
        width={{xs: '100vw', md: 'auto'}}
        display='flex'
        flexDirection='column'
        height={{ xs: !isLastSearched && 'calc(100vh - 136px)', md: 'initial' }}
        overflow={{ xs: 'auto', md: 'visible' }}
        mx={-3}
        px={3}
        pb={{xs: !isLastSearched && 15, md: 0}}
    >
        {options.map((option) => <Option
            option={option}
            key={option.id}
            isWithParents={isWithParents}
            onClick={() => onChangeLocation(option)}
        />)}
    </Box>;
};
