import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ICategories
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import {
    ICategory
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import { SetQueryParamCb } from 'components/advertisementsFilter/types/queryFilter/queryParam/SetQueryParamCb';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { useParentCategories } from 'components/advertisementsFilter/hooks/categories/useParentCategories';
import {
    apiCategoryToECategoryNameRecord
} from 'components/advertisementsFilter/constants/record/category/apiCategoryToECategoryNameRecord';
import { ECategoryValue } from 'components/advertisementsFilter/enums/category/ECategoryValue';

interface IReturn {
    categoryOptions: ICategories;
    onCategoriesReset: () => void;
    selectedCategoryIds: number[];
    selectedCategoryNames: string;
    selectedCategories: ICategory[];
    selectedSubcategoryNames: string;
    onSelectCategory: (categoryId: number) => void;
    onDeselectCategory: (categoryId: number) => void;
    selectedCategoryEnumNames: (EMainCategory | ESubCategory)[];
}

export const useSearchFilterCategories = (
    initialCategories: ICategories,
    setQueryParameter: SetQueryParamCb,
    categoriesFromFilter?: (EMainCategory | ESubCategory)[],
): IReturn => {
    const [allCategories, setAllCategories] = useState<ICategory[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<ICategory[]>([]);

    const { excludeOrIncludeParentCategory } = useParentCategories();

    useEffect(() => {
        setAllCategories([
            ...initialCategories.main,
            ...initialCategories.sub,
        ]);
    }, [initialCategories.main, initialCategories.sub]);

    useEffect(() => {
        if (!categoriesFromFilter || !categoriesFromFilter.length) return;

        const categoriesToSet: ICategory[] = [];
        allCategories.forEach((category) => {
            if (categoriesFromFilter.includes(category.name)) {
                categoriesToSet.push(category);
            }
        });

        setSelectedCategories(categoriesToSet);
    }, [categoriesFromFilter, allCategories]);

    useEffect(() => {
        setQueryParameter('categories', [
            ...selectedCategories.map((category) => category.name),
        ]);
    }, [selectedCategories, setQueryParameter]);

    const onSelectCategory = (categoryId: number): void => {
        const categoryToSelect = allCategories.find(
            (category) => category.id === categoryId,
        );

        if (categoryToSelect) {
            setSelectedCategories([
                ...excludeOrIncludeParentCategory(
                    categoryToSelect,
                    selectedCategories,
                ),
                categoryToSelect,
            ]);
        }
    };

    const onDeselectCategory = useCallback((categoryId: number): void => {
        const updatedCategories = selectedCategories.filter(
            (selectedCategory) => selectedCategory.id !== categoryId,
        );

        setSelectedCategories(updatedCategories);
    }, [selectedCategories]);

    const selectedCategoryIds = useMemo(() => {
        const ids: number[] = [];
        selectedCategories.forEach((selectedCategory) => {
            const parentCategory = allCategories.find(
                (category) => category.id === selectedCategory.parentId);

            if (parentCategory && !ids.includes(parentCategory.id)) {
                ids.push(parentCategory.id);
            }

            ids.push(selectedCategory.id);
        });

        return ids;
    }, [selectedCategories, allCategories]);

    const selectedCategoryEnumNames = useMemo(() => {
        return selectedCategories.map((selectedCategory) => selectedCategory.name);
    }, [selectedCategories]);

    const selectedCategoryNames = useMemo(() => {
        return selectedCategories
            .map((selectedCategory) => apiCategoryToECategoryNameRecord[selectedCategory.name])
            .join(', ');
    }, [selectedCategories]);

    const selectedSubcategoryNames = useMemo(() => {
        const subCategoryNames: string[] = [];

        selectedCategories.forEach((selectedCategory) => {
            const isParentCategoryApartments =
                selectedCategory.parentId &&
                selectedCategory.parentId === ECategoryValue.APARTMENTS;

            if (isParentCategoryApartments && selectedCategory) {
                subCategoryNames.push(apiCategoryToECategoryNameRecord[selectedCategory.name]);
            }
        });

        return subCategoryNames.length ? subCategoryNames.join(', ') : 'Nezáleží';
    }, [selectedCategories]);

    const onCategoriesReset = (): void => {
        setSelectedCategories([]);
        setQueryParameter('categories', null);
    };

    return {
        onSelectCategory,
        onCategoriesReset,
        selectedCategories,
        onDeselectCategory,
        selectedCategoryIds,
        selectedCategoryNames,
        selectedSubcategoryNames,
        selectedCategoryEnumNames,
        categoryOptions: initialCategories,
    };
};
