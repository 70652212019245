'use client';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import {Box, useTheme} from '@mui/material';
import {Keyboard, Pagination, Navigation, Autoplay} from 'swiper';
import {FC, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Swiper as SwiperClass} from 'swiper/types';
import {useCarousel} from 'components/carousel/hooks/useCarousel';
import NextIcon from 'modules/theme/components/icons/basic/KeyboardArrowRight.svg?url';
import PrevIcon from 'modules/theme/components/icons/basic/KeyboardArrowLeft.svg?url';
import { useHydration } from 'modules/page/app/useHydration';

interface ICarousel {
    items: JSX.Element[],
    spaceBetween?: number,
    infinityLoop?: boolean,
    activeItemIndex?: number,
    showPagination?: boolean,
    centeredSlides?: boolean,
    showNavigation?: boolean,
    isDynamicallyUpdated?: boolean,
    showingCountOfItems?: number | 'auto',
    autoplayMs?: number,
    onSlideChange?: (swiper: SwiperClass) => void,
    maxScreenWidth?: boolean,
}

export const Carousel: FC<ICarousel> = (
    {
        items,
        onSlideChange,
        activeItemIndex,
        spaceBetween = 16,
        infinityLoop = false,
        centeredSlides = false,
        showNavigation = false,
        showPagination = false,
        showingCountOfItems = 'auto',
        isDynamicallyUpdated = false,
        maxScreenWidth,
        autoplayMs = 0,
    }) => {

    if (isDynamicallyUpdated && infinityLoop) {
        throw Error('You cannot use isDynamicallyUpdated and infinityLoop at same time');
    }

    const {palette} = useTheme();
    const [swiper, setSwiper] = useState<SwiperClass>();
    const {isBeginning, setIsBeginning, isEnd, setIsEnd, swiperRef} = useCarousel();
    useHydration();

    const allowSlideNext = isDynamicallyUpdated || infinityLoop ? true : !isEnd;
    const allowSlidePrev = infinityLoop ? true : !isBeginning;

    useEffect(() => {
        if (swiper && !isNaN(activeItemIndex)) {
            swiper.slideTo(activeItemIndex);
        }
    }, [swiper, activeItemIndex]);

    return <>
        <Box sx={{
            maxWidth: maxScreenWidth ? '100%' : 'none',
            position: 'relative',
            '& .swiper-pagination': {
                marginLeft: '-8px!important'
            },
            '& .swiper-pagination-bullet': {
                backgroundColor: `${palette.backgroundTertiary.main}!important`,
                opacity: '100%!important'
            },
            '.swiper-pagination-bullet:not(:last-of-type)': {
                marginRight: '8px!important',
            },
            '& .swiper-pagination-bullet-active': {
                backgroundColor: `${palette.labelPrimary.main}!important`,
                width: `${16}px!important`,
                borderRadius: `${6}px!important`
            },
            '& .swiper': {
                '.swiper-wrapper': {
                    alignItems: 'center',
                    marginBottom: '16px'
                }
            },
            '.swiper-button-next': {
                width: 56,
                minWidth: 56,
                height: 56,
                minHeight: 56,
                top: '40%!important',
                right: '-27px!important',
                borderRadius: '100%',
                cursor: 'pointer',
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                color: palette.labelPrimary.main,
                display: allowSlideNext ? 'flex' : 'none!important',
                boxShadow: '0px 16px 32px rgba(32, 20, 20, 0.08)',
                border: `1px solid ${palette.labelQuarternary.main}`,
                backgroundColor: palette.backgroundPrimary.main,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url("${NextIcon.src}")`,
            },
            '.swiper-button-prev': {
                width: 56,
                minWidth: 56,
                height: 56,
                minHeight: 56,
                top: '40%!important',
                left: '-27px!important',
                borderRadius: '100%',
                cursor: 'pointer',
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                display: allowSlidePrev ? 'flex' : 'none!important',
                boxShadow: '0px 16px 32px rgba(34, 34, 34, 0.08)',
                border: `1px solid ${palette.labelQuarternary.main}`,
                backgroundColor: palette.backgroundPrimary.main,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url("${PrevIcon.src}")`,
            },
            '.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled': {
                opacity: 0,
            },
            '.swiper-button-next::after, .swiper-button-prev::after': {
                display: 'none',
            }
        }}>
            <Swiper
                ref={swiperRef}
                className="mySwiper"
                keyboard={{enabled: true}}
                spaceBetween={spaceBetween}
                loop={infinityLoop && items.length > 1}
                pagination={isDynamicallyUpdated &&  showPagination ? {dynamicBullets: true, dynamicMainBullets: 4} : showPagination ? true : false}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                centeredSlides={centeredSlides}
                allowSlideNext={allowSlideNext}
                allowSlidePrev={allowSlidePrev}
                {...(autoplayMs > 0 && {
                    autoplay: {
                        delay: autoplayMs,
                        disableOnInteraction: false,
                    }
                })}
                modules={[
                    Keyboard,
                    Pagination,
                    ...(showNavigation ? [Navigation] : []),
                    ...(autoplayMs > 0 ? [Autoplay] : []),

                ]}
                slidesPerView={showingCountOfItems}
                onInit={swiper => setSwiper(swiper)}
                onSlideChange={swiper => {
                    setIsEnd(swiper.isEnd);
                    setIsBeginning(swiper.isBeginning);
                    onSlideChange && onSlideChange(swiper);
                }}
                style={{
                    display: 'flex',
                    position: 'relative',
                    paddingBottom: showPagination ? '3rem' : 0,
                }}
            >

                {items.map((item, index) =>
                    <SwiperSlide key={index} style={{width: 'auto'}}>
                        {item}
                    </SwiperSlide>
                )}
            </Swiper>
            {showNavigation && (
                <>
                    <Box className="swiper-button-prev"></Box>
                    <Box className="swiper-button-next"></Box>
                </>
            )}
        </Box>
    </>;
};
