import { FC } from 'react';
import { Grid } from '@mui/material';
import { IGridCategory } from 'components/advertisementsFilter/interfaces/category/grid/IGridCategory';
import {
    CategoryGridItem
} from 'components/advertisementsFilter/components/common/common/category/partials/completeBox/partials/rollableCategories/partials/gridItems/patials/item/Item';

interface ICategoryGridItems {
    items: IGridCategory[];
    onChangeCategory: (id: number) => void;
}

export const CategoryGridItems: FC<ICategoryGridItems> = ({
    items,
    onChangeCategory
}) => {

    return <Grid container direction='column' spacing={2}>
        {items.map((gridItem) =>
            <CategoryGridItem
                item={gridItem}
                key={gridItem.id}
                onChange={onChangeCategory}
            />
        )}
    </Grid>;
};