import { AdvertisementsListDto } from 'modules/api/generated';
import { IAdvertisementsList } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import { mapAdvertisementsListResultDtoToIAdvertisementsListResult } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/mapAdvertisementsListResultDtoToIAdvertisementsListResult';
import { mapBreadcrumbDtoToIBreadcrumb } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/breadcrumb/mapBreadcrumbDtoToIBreadcrumb';

export const mapAdvertisementsListDtoToIAdvertisementsList = (
    dto: AdvertisementsListDto
): IAdvertisementsList => ({
    title: dto.title,
    locationCountySlug: '',
    totalCount: dto.totalCount,
    breadcrumb: mapBreadcrumbDtoToIBreadcrumb(dto.breadcrumb.parts),
    quickLinks: mapBreadcrumbDtoToIBreadcrumb(dto.quickLinks.parts),
    results: dto.results.map(
        mapAdvertisementsListResultDtoToIAdvertisementsListResult
    ),
});
