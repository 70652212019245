import { FC } from 'react';
import { Stack } from '@mui/material';
import {
    ContentItem
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterHeader/partials/multiInput/partials/content/partials/item/Item';

interface IInputContent {
    location?: string;
    category?: string;
    transaction?: string;
}

export const InputContent: FC<IInputContent> = ({
    location,
    category,
    transaction,
}) => {
    const locationText = location ? location : 'Kdekoľvek';
    const categoryText = category ? category : 'Nehnuteľnosť';
    const transactionText = transaction ? transaction : 'Ponuka';

    return <Stack direction='column' overflow='hidden'>
        <ContentItem variant='label1' text={locationText} semibold noWrap/>

        <Stack overflow='hidden' direction='row' gap={1}>
            <ContentItem noWrap variant='label2' text={categoryText} isColorSecondary={!category}/>
            <ContentItem variant='label2' text={'-'} isColorSecondary={!transaction && !category}/>
            <ContentItem variant='label2' noWrap text={transactionText} isColorSecondary={!transaction}/>
        </Stack>
    </Stack>;
};