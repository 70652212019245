import { useState, useCallback, useMemo, useEffect } from 'react';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';
import { SetQueryParamCb } from 'components/advertisementsFilter/types/queryFilter/queryParam/SetQueryParamCb';

interface IReturn {
    selectedTransaction?: ITransaction;
    transactionOptions: ITransaction[];
    onChangeTransaction: (value: number | null) => void;
}

export const useSearchFilterTransaction = (
    initialTransactions: ITransaction[],
    setQueryParameter: SetQueryParamCb,
    transactionFromFilter?: ETransaction,
): IReturn => {
    const [selectedTransaction, setSelectedTransaction] = useState<ITransaction>();

    useEffect(() => {
        if (!transactionFromFilter) return;

        const transationToSet = initialTransactions.find(
            (transaction) => transaction.name === transactionFromFilter);

        if (transationToSet) {
            setSelectedTransaction(transationToSet);
            setQueryParameter('transaction', transationToSet.name);
        }
    }, [transactionFromFilter, initialTransactions, setQueryParameter]);

    const transactionsForFilter = useMemo(() => {
        return initialTransactions.filter((value) =>
            value.name === ETransaction.SALE
            || value.name === ETransaction.HIREOUT
            || value.name === ETransaction.PURCHASE
        );
    }, [initialTransactions]);

    const onChangeTransaction = useCallback((value: number): void => {
        if (!value) {
            setSelectedTransaction(null);
            setQueryParameter('transaction', null);
        }
        
        const transactionOption = initialTransactions.length
            && initialTransactions.find((option) => option.id === value);

        if (transactionOption) {
            setSelectedTransaction(transactionOption);
            setQueryParameter('transaction', transactionOption && transactionOption.name);
        }
    }, [initialTransactions, setQueryParameter]);

    return {
        selectedTransaction,
        onChangeTransaction,
        transactionOptions: transactionsForFilter,
    };
};
