import React, {FC, FunctionComponent, SVGProps} from 'react';
import {Box, useMediaQuery, Theme} from '@mui/material';
import {
    RadioCardContent
} from 'components/advertisementsFilter/components/common/common/radioCard/common/content/RadioCardContent';

interface ICategoryRadioCard {
    label: string;
    isMain?: boolean;
    small?: boolean;
    noTitle?: boolean;
    isChecked: boolean;
    fullWidth?: boolean;
    onClick: () => void;
    width?: string | number;
    height?: number | string;
    titleHeight?: string | number;
    type: 'category' | 'subcategory';
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const CategoryRadioCard: FC<ICategoryRadioCard> = ({
    type,
    icon,
    small,
    label,
    width,
    isMain,
    height,
    noTitle,
    onClick,
    fullWidth,
    isChecked,
}) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const isSmDown = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSubcategory = type === 'subcategory';
    if (!width) {
        width = fullWidth ? '100%' : small ? 48 : isSmDown ? 'calc(33% - 5px)' : 124;
    }

    return (
        <Box
            p={1.5}
            tabIndex={-1}
            height={height}
            onClick={onClick}
            alignItems='center'
            borderRadius='12px'
            gap={isMain ? 0.5 : 1}
            width={isMobile ? '100%' : width}
            data-test-id='categoryRadioCardCustom'
            flexDirection={isMain ? 'column' : 'row'}
            textAlign={isSubcategory ? 'left' : 'center'}
            justifyContent={isMain ? 'center' : 'flex-start'}
            display={isMain || isSubcategory ? 'flex' : 'block'}
            sx={(theme) => ({
                cursor: 'pointer',
                outline: isChecked
                    ? `1px solid ${theme.palette.labelPrimary.main}`
                    : `1px solid ${theme.palette.dividerTransparent}`,
                backgroundColor: isChecked
                    ? theme.palette.backgroundSecondary.main
                    : theme.palette.backgroundPrimary.main,
                border: isChecked
                    ? `1px solid ${theme.palette.labelPrimary.main}`
                    : '1px solid transparent',
                '&:hover': {
                    border: `1px solid ${theme.palette.labelPrimary.main}`,
                    outline: `1px solid ${theme.palette.labelPrimary.main}`,
                    backgroundColor: !isMobile && theme.palette.backgroundSecondary.main
                },
                '@media (hover: none)': {
                    '&:hover': {
                        outline: isChecked
                            ? `1px solid ${theme.palette.labelPrimary.main}`
                            : `1px solid ${theme.palette.dividerTransparent}`,
                        border: isChecked
                            ? `1px solid ${theme.palette.labelPrimary.main}`
                            : '1px solid transparent',
                    },
                },
                ...(isMobile && {
                    '& .MuiTypography-root': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical'
                    }

                }),
            })}
        >
            <RadioCardContent
                icon={icon}
                label={label}
                noTitle={noTitle}
                iconWidth={!isSubcategory
                    ? 32
                    : isMobile
                        ? 24
                        : 16
                }
                noWrap={isSubcategory && !isMobile}
                isTextBold={isChecked && !isSubcategory}
            />
        </Box>
    );
};