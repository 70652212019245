import { useCallback } from 'react';
import {
    IAdvertisementListingFilter
} from 'modules/api/clients/advertisement/filter/interfaces/IAdvertisementListingFilter';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { useLocationsAutocomplete } from 'components/advertisementsFilter/hooks/location/autocomplete/useLocationsAutocomplete';
import {
    IFilterUrlDataLocation
} from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/partials/location/IFilterUrlDataLocation';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import {
    ICategories
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import {
    useSearchFilterCategories
} from 'components/advertisementsFilter/hooks/categories/useSearchFilterCategories';
import {
    useSearchFilterTransaction
} from 'components/advertisementsFilter/hooks/transaction/useSearchFilterTransaction';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { SetQueryParamCb } from 'components/advertisementsFilter/types/queryFilter/queryParam/SetQueryParamCb';
import { ICategory } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';


interface IReturn {
    locationQuery: string;
    selectedLocation: ILocation;
    categoryOptions: ICategories;
    selectedCategoryNames: string;
    selectedCategoryIds: number[];
    onCategoriesReset: () => void;
    selectedCategories: ICategory[];
    onSearchFilterReset: () => void;
    selectedSubcategoryNames: string;
    selectedTransaction: ITransaction;
    transactionOptions: ITransaction[];
    locationOptionsInitial: ILocation[];
    autocompleteLocationOptions: ILocation[];
    onSelectCategory: (value: number) => void;
    onDeselectCategory: (value: number) => void;
    onChangeTransaction: (value: number) => void;
    onChangeLocationQuery: (value: string) => void;
    onChangeLocation: (location: ILocation) => void;
    selectedCategoryEnumNames: (EMainCategory | ESubCategory)[];
}

export const useSearchFilter = (
    initialFilter: IAdvertisementListingFilter,
    searchFilterData: IInitialSearchFilterData,
    setQueryParameter: SetQueryParamCb,
    locationFromSef?: IFilterUrlDataLocation,
): IReturn => {
    const {
        transactionOptions,
        selectedTransaction,
        onChangeTransaction,
    } = useSearchFilterTransaction(
        searchFilterData.transactions,
        setQueryParameter,
        initialFilter.data.transaction,
    );

    const {
        categoryOptions,
        onSelectCategory,
        onCategoriesReset,
        selectedCategories,
        onDeselectCategory,
        selectedCategoryIds,
        selectedCategoryNames,
        selectedSubcategoryNames,
        selectedCategoryEnumNames,
    } = useSearchFilterCategories(
        searchFilterData.categories,
        setQueryParameter,
        initialFilter.data.categories,
    );

    const {
        locationQuery,
        onChangeLocation,
        selectedLocation,
        locationOptionsInitial,
        options: autocompleteLocationOptions,
        onChangeQuery: onChangeLocationQuery,
    } = useLocationsAutocomplete(
        searchFilterData.locations,
        setQueryParameter,
        locationFromSef,
    );

    const onSearchFilterReset = useCallback((): void => {
        onCategoriesReset();
        onChangeLocation(null);
        onChangeTransaction(null);
        onChangeLocationQuery('');
    }, [
        onChangeLocation,
        onCategoriesReset,
        onChangeTransaction,
        onChangeLocationQuery,
    ]);

    return {
        locationQuery,
        categoryOptions,
        selectedLocation,
        onChangeLocation,
        onSelectCategory,
        onCategoriesReset,
        onDeselectCategory,
        selectedCategories,
        transactionOptions,
        selectedTransaction,
        onChangeTransaction,
        onSearchFilterReset,
        selectedCategoryIds,
        onChangeLocationQuery,
        selectedCategoryNames,
        locationOptionsInitial,
        selectedSubcategoryNames,
        selectedCategoryEnumNames,
        autocompleteLocationOptions,
    };
};