import { FC } from 'react';
import { Grid, useMediaQuery, Theme } from '@mui/material';
import {
    CategoryRadioCard
} from 'components/advertisementsFilter/components/common/common/radioCard/category/CategoryRadioCard';
import {
    ICategory
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import { categoryIconRecord } from 'components/advertisementsFilter/constants/record/category/categoryIconRecord';
import {
    apiCategoryToECategoryNameRecord
} from 'components/advertisementsFilter/constants/record/category/apiCategoryToECategoryNameRecord';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import {
    boxWidthConfig,
    boxHeightConfig,
} from 'components/advertisementsFilter/constants/config/completeBoxes/boxWidthAndHeight/boxWidthAndHeightConfig';

interface IMainCategories {
    options: ICategory[];
    selectedCategoryIds: number[];
    onChangeCategory: (category: number) => void;
}

export const MainCategories: FC<IMainCategories> = ({
    options,
    onChangeCategory,
    selectedCategoryIds,
}) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const isSmOrDown = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const xsGridWidth = (name: EMainCategory | ESubCategory): number => {
        return (name === EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES ||
            name === EMainCategory.SECONDARY_SPACES_AND_OBJECTS ) ? 6 : 4;
    };

    return <Grid
        container
        spacing={1}
        alignItems='center'
    >
        {options.map((categoryOption) =>
            <Grid
                item
                md='auto'
                key={categoryOption.id}
                xs={xsGridWidth(categoryOption.name)}
            >
                <CategoryRadioCard
                    isMain
                    type='category'
                    height={isMobile
                        ? boxHeightConfig.category.main.mobile
                        : boxHeightConfig.category.main.desktop
                    }
                    icon={categoryIconRecord[categoryOption.name]}
                    onClick={() => onChangeCategory(categoryOption.id)}
                    isChecked={selectedCategoryIds.includes(categoryOption.id)}
                    width={isSmOrDown ? '100%' : boxWidthConfig.category.default}
                    label={apiCategoryToECategoryNameRecord[categoryOption.name]}
                />
            </Grid>
        )}
    </Grid>;
};