import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
    fetchAdvertisementsTotalCount
} from 'components/advertisementsFilter/api/totalCount/fetchAdvertisementsTotalCount';
import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

interface IReturn {
    totalCount: number;
    isFetching: boolean;
}

export const useAdvertisementsTotalCountByFilter = (
    locationId?: string,
    transaction?: ETransaction,
    categories?: (EMainCategory | ESubCategory)[],
    priceFrom?: number,
    priceTo?: number,
    areaFrom?: number,
    areaTo?: number,
    onlyNewbuilding?: boolean,
    onlyWithVideo?: boolean,
    fulltext?: string
): IReturn  => {
    const [isFetching, setIsFetching] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const getAdvertisementsTotalCount = useCallback(async () => {
        try {
            setIsFetching(true);
            const totalCount = await fetchAdvertisementsTotalCount({
                categories,
                locationId,
                transaction,
                priceFrom,
                priceTo,
                areaFrom,
                areaTo,
                onlyNewbuilding,
                onlyWithVideo,
                fulltext,
            });

            setTotalCount(totalCount);
        } catch (error) {
            setTotalCount(0);
        } finally {
            setIsFetching(false);
        }
    }, [
        areaTo,
        priceTo,
        fulltext,
        areaFrom,
        priceFrom,
        locationId,
        categories,
        transaction,
        onlyWithVideo,
        onlyNewbuilding,
    ]);

    const debouncedTotalCountFetch = useDebouncedCallback(getAdvertisementsTotalCount, 250);

    useEffect(() => {
        (async () => {
            await debouncedTotalCountFetch();
        })();
    }, [debouncedTotalCountFetch, getAdvertisementsTotalCount]);

    return {
        isFetching,
        totalCount,
    };
};