import { useState, useCallback, useMemo } from 'react';
import { EInputs } from 'components/advertisementsFilter/enums/inputs/EInputs';

interface IReturn {
    inputInFocus: EInputs;
    isNothingInFocus: boolean;
    isNothingHovered: boolean;
    isLocationHovered: boolean;
    isCategoryHovered: boolean;
    isLocationInFocus: boolean;
    isCategoryInFocus: boolean;
    isTransactionInFocus: boolean;
    isTransactionHovered: boolean;
    onChangeHoverState: (value: EInputs) => void;
    onChangeInputInFocus: (value: EInputs) => void;
}

export const useInputFocus = (
    isLocationPopoverOpened: boolean,
    isTransactionPopoverOpened: boolean,
    isCategoryPopoverOpened: boolean
): IReturn => {
    const [inputInFocus, setInputInFocus] = useState<EInputs>();
    const [inputHovered, setInputHovered] = useState<EInputs>();

    const onChangeInputInFocus = useCallback((value: EInputs) => {
        setInputInFocus(value);
    }, []);

    const onChangeHoverState = useCallback((value: EInputs) => {
        setInputHovered(value);
    }, []);

    const isLocationInFocus = useMemo(() =>
        inputInFocus === EInputs.LOCATION || isLocationPopoverOpened,
    [inputInFocus, isLocationPopoverOpened]);

    const isCategoryInFocus = useMemo(() =>
        inputInFocus === EInputs.CATEGORY || isCategoryPopoverOpened,
    [inputInFocus, isCategoryPopoverOpened]);

    const isTransactionInFocus = useMemo(() =>
        inputInFocus === EInputs.TRANSACTION || isTransactionPopoverOpened,
    [inputInFocus, isTransactionPopoverOpened]);

    const isLocationHovered = useMemo(() =>
        inputHovered === EInputs.LOCATION,
    [inputHovered]);

    const isCategoryHovered = useMemo(() =>
        inputHovered === EInputs.CATEGORY,
    [inputHovered]);

    const isTransactionHovered = useMemo(() =>
        inputHovered === EInputs.TRANSACTION,
    [inputHovered]);

    return {
        inputInFocus,
        isCategoryHovered,
        isLocationInFocus,
        isCategoryInFocus,
        isLocationHovered,
        onChangeHoverState,
        isTransactionHovered,
        isTransactionInFocus,
        onChangeInputInFocus,
        isNothingHovered: !isLocationHovered && !isCategoryHovered && !isTransactionHovered,
        isNothingInFocus: !isLocationInFocus && !isCategoryInFocus && !isTransactionInFocus,
    };
};