import {FunctionComponent, SVGProps} from 'react';

import Room1Icon from 'modules/theme/components/icons/category/Room1.svg';
import Room2Icon from 'modules/theme/components/icons/category/Room2.svg';
import Room3Icon from 'modules/theme/components/icons/category/Room3.svg';
import Room4Icon from 'modules/theme/components/icons/category/Room4.svg';
import Room5Icon from 'modules/theme/components/icons/category/Room5.svg';
import StudioIcon from 'modules/theme/components/icons/category/Studio.svg';
import MaisonetteIcon from 'modules/theme/components/icons/category/Maisonette.svg';
import LoftIcon from 'modules/theme/components/icons/category/Loft.svg';
import HolidayApartmentIcon from 'modules/theme/components/icons/category/HolidayApartment.svg';
import ApartmentOutlineIcon from 'modules/theme/components/icons/category/ApartmentOutline.svg';
import LandOutlineIcon from 'modules/theme/components/icons/category/LandOutline.svg';
import HouseOutlineIcon from 'modules/theme/components/icons/category/HouseOutline.svg';
import CabinOutlineIcon from 'modules/theme/components/icons/category/CabinOutline.svg';
import SpaceOutlineIcon from 'modules/theme/components/icons/category/SpaceOutline.svg';
import CommercionalLandIcon from 'modules/theme/components/icons/category/CommercionalLand.svg';
import FamilyHouseLandIcon from 'modules/theme/components/icons/category/FamilyHouseLand.svg';
import ForestLandIcon from 'modules/theme/components/icons/category/ForestLand.svg';
import VineyardsLandIcon from 'modules/theme/components/icons/category/VineyardsLand.svg';
import GarageIcon from 'modules/theme/components/icons/category/Garage.svg';
import GardenIcon from 'modules/theme/components/icons/category/Garden.svg';
import OfficesIcon from 'modules/theme/components/icons/category/Offices.svg';
import RecreationLandIcon from 'modules/theme/components/icons/category/RecreationLand.svg';
import RestaurantIcon from 'modules/theme/components/icons/category/Restaurant.svg';
import StoreIcon from 'modules/theme/components/icons/category/Store.svg';
import HotelIcon from 'modules/theme/components/icons/category/Hotel.svg';
import WarehouseIcon from 'modules/theme/components/icons/category/Warehouse.svg';
import FactoryIcon from 'modules/theme/components/icons/category/Factory.svg';
import OtherObjectsIcon from 'modules/theme/components/icons/category/OtherObjects.svg';

import { SubCategoriesUsed } from 'components/advertisementsFilter/enums/category/used/ESubCategoriesUsed';
import { MainCategoriesUsed } from 'components/advertisementsFilter/enums/category/used/EMainCategoriesUsed';

export const categoryIconRecord: Record<MainCategoriesUsed | SubCategoriesUsed, FunctionComponent<SVGProps<SVGSVGElement>>> = {
    [MainCategoriesUsed.REAL_ESTATES]: ApartmentOutlineIcon,
    [MainCategoriesUsed.APARTMENTS]: ApartmentOutlineIcon,
    [MainCategoriesUsed.SECONDARY_HOUSES]: HouseOutlineIcon,
    [MainCategoriesUsed.SECONDARY_RECREATIONAL_PROPERTIES]: CabinOutlineIcon,
    [MainCategoriesUsed.SECONDARY_LANDS]: LandOutlineIcon,
    [MainCategoriesUsed.SECONDARY_SPACES_AND_OBJECTS]: SpaceOutlineIcon,

    [SubCategoriesUsed.SECONDARY_STUDIO_APARTMENT]: StudioIcon,
    [SubCategoriesUsed.ONE_ROOM_APARTMENT]: Room1Icon,
    [SubCategoriesUsed.TWO_ROOM_APARTMENT]: Room2Icon,
    [SubCategoriesUsed.SECONDARY_THREE_ROOM_APARTMENT]: Room3Icon,
    [SubCategoriesUsed.FOUR_ROOM_APARTMENT]: Room4Icon,
    [SubCategoriesUsed.FIVE_PLUS_ROOM_APARTMENT]: Room5Icon,
    [SubCategoriesUsed.HOLIDAY_APARTMENT]: HolidayApartmentIcon,
    [SubCategoriesUsed.MAISONETTE]: MaisonetteIcon,
    [SubCategoriesUsed.LOFT]: LoftIcon,

    [SubCategoriesUsed.SECONDARY_LAND_FOR_FAMILY_HOUSES]: FamilyHouseLandIcon,
    [SubCategoriesUsed.SECONDARY_RECREATIONAL_LAND]: RecreationLandIcon,
    [SubCategoriesUsed.SECONDARY_COMMERCIAL_PLOTS]: CommercionalLandIcon,
    [SubCategoriesUsed.SECONDARY_GARDEN]: GardenIcon,
    [SubCategoriesUsed.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: VineyardsLandIcon,
    [SubCategoriesUsed.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: ForestLandIcon,

    [SubCategoriesUsed.SECONDARY_GARAGE_AND_PARKING]: GarageIcon,
    [SubCategoriesUsed.SECONDARY_OFFICES_AND_ADMINISTRATION]: OfficesIcon,
    [SubCategoriesUsed.SECONDARY_THE_SHOP]: StoreIcon,
    [SubCategoriesUsed.SECONDARY_A_RESTAURANT]: RestaurantIcon,
    [SubCategoriesUsed.SECONDARY_WAREHOUSES]: WarehouseIcon,
    [SubCategoriesUsed.SECONDARY_PRODUCTION]: FactoryIcon,
    [SubCategoriesUsed.SECONDARY_HOTEL_GUESTHOUSE]: HotelIcon,
    [SubCategoriesUsed.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: OtherObjectsIcon,
};