import { FC } from 'react';
import { Box } from '@mui/material';
import {
    LocationInput
} from 'components/advertisementsFilter/components/common/common/location/partials/input/LocationInput';
import {
    CompleteBox
} from 'components/advertisementsFilter/components/common/common/location/partials/completeBox/CompleteBox';
import { Popover } from 'components/advertisementsFilter/components/common/common/popover/Popover';
import {
    Autocomplete
} from 'components/advertisementsFilter/components/common/common/location/partials/autocomplete/Autocomplete';
import { useLocationVisibility } from 'components/advertisementsFilter/hooks/location/visibility/useLocationVisibility';
import { ILocation as ILocationOption } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    usePreviouslySearchedLocations
} from 'components/advertisementsFilter/hooks/location/previouslySearched/usePreviouslySearchedLocations';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { EInputs } from 'components/advertisementsFilter/enums/inputs/EInputs';

interface ILocation {
    isInFocus: boolean;
    isHovered: boolean;
    locationQuery: string;
    isPopoverOpen: boolean;
    onClosePopover: () => void;
    popoverAnchorEl: HTMLElement;
    selectedLocation: ILocationOption;
    autocompleteOptions: IAutocompleteOption[];
    locationOptionsInitial: ILocationOption[];
    onSetInputInFocus: (value: EInputs) => void;
    onChangeHoverState: (flag: EInputs) => void;
    onOpenPopover: (target: HTMLElement) => void;
    onChangeLocationQuery: (value: string) => void;
    onChangeLocation: (location: ILocationOption) => void;
}

export const Location: FC<ILocation> = ({
    isInFocus,
    isHovered,
    locationQuery,
    onOpenPopover,
    isPopoverOpen,
    onClosePopover,
    popoverAnchorEl,
    selectedLocation,
    onChangeLocation,
    onSetInputInFocus,
    onChangeHoverState,
    autocompleteOptions,
    onChangeLocationQuery,
    locationOptionsInitial,
}) => {
    const {
        options: previouslySearchedOptions,
    } = usePreviouslySearchedLocations();

    const {
        showBaseLocations,
        showPreviouslySearchedLocations,
    } = useLocationVisibility(
        locationQuery,
        selectedLocation,
        autocompleteOptions,
        previouslySearchedOptions,
    );

    const renderLastSearchedAutocomplete = !!showPreviouslySearchedLocations;
    const renderCompleteBox = !renderLastSearchedAutocomplete
        && (showBaseLocations || !autocompleteOptions.length);

    const popoverComponent = renderLastSearchedAutocomplete
        ? <Autocomplete
            width={360}
            title='Naposledy ste hľadali:'
            options={previouslySearchedOptions}
            onChangeLocation={(value) => {
                onChangeLocation(value);
                onClosePopover();
            }}
        />
        : renderCompleteBox
            ? <CompleteBox
                options={locationOptionsInitial}
                onChangeLocation={(value) => {
                    onChangeLocation(value);
                    onClosePopover();
                }}
                selectedLocationId={selectedLocation && selectedLocation.id}
            />
            : <Autocomplete
                width={360}
                options={autocompleteOptions}
                onChangeLocation={(value) => {
                    onChangeLocation(value);
                    onClosePopover();
                }}
            />;

    return <>
        <LocationInput
            label='Lokalita'
            inputWidth={264}
            isInFocus={isInFocus}
            isHovered={isHovered}
            value={locationQuery}
            clearButtonMargin={10}
            onClick={onOpenPopover}
            placeholder='Kde hľadáte?'
            onChange={onChangeLocationQuery}
            onSetInputInFocus={onSetInputInFocus}
            onChangeHoverState={onChangeHoverState}
            onClearLocation={() => {
                onChangeLocation(null);
                onChangeLocationQuery('');
            }}
        />

        <Popover
            isOpen={isPopoverOpen}
            onClose={() => {
                onClosePopover();
                onSetInputInFocus(null);
            }}
            anchorEl={popoverAnchorEl}
            offsetOptions={{
                x: renderCompleteBox ? 100 : 45,
                y: 20
            }}
        >
            <Box minWidth={300}>
                {popoverComponent}
            </Box>
        </Popover>
    </>;
};