import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import SearchIcon from 'modules/theme/components/icons/basic/Search.svg';
import { Button } from 'modules/theme/components/button/Button';
import {
    SearchButtonWrapper
} from 'components/advertisementsFilter/components/common/desktopFilter/partials/searchButton/partials/wrapper/ButtonWrapper';

interface ISearchButton {
    onClick?: () => void;
    isMainFilter?: boolean;
    isTitleVisible?: boolean;
}

export const SearchButton: FC<ISearchButton> = ({ isTitleVisible, isMainFilter, onClick }) => {

    return <SearchButtonWrapper isTitleVisible={isTitleVisible}>
        <Button
            rounded
            small
            onClick={onClick}
            ariaLabel={'search-button'}
            text={(
                <Box
                    gap={1}
                    display='flex'
                    alignItems='center'
                    position='relative'
                >
                    <Svg
                        icon
                        width={16}
                        component={SearchIcon}
                        sx={{
                            top: isMainFilter && !isTitleVisible && '50%',
                            left: isMainFilter && !isTitleVisible && '50%',
                            position: isMainFilter && !isTitleVisible ? 'absolute' : 'initial',
                            transform: isMainFilter && !isTitleVisible && 'translate(-50%, -50%)'
                        }}
                    />

                    {isMainFilter &&
                        <Box visibility={isTitleVisible ? 'visible' : 'hidden'}>
                            <Text
                                semibold
                                variant='label1'
                                color={(pallete) => pallete.labelPrimaryInverse}
                            >
                                Hľadať
                            </Text>
                        </Box>
                    }
                </Box>
            )}
        />
    </SearchButtonWrapper>;
};